import React from "react"
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../components/theme'
import '../css/style.css'
import Nav from '../components/_nav'
import PageMacro from '../components/page_macros_sidebar'
import Footer from '../components/footer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'


//base_url, post_date, post_cat, post_cat_name, post_slug, post_slug_name, post_descp_kps,
//cat_img, nutrinfo, description, lang, keywords, title, canonical

const MacrosPages = props => {

    const { pageContext } = props;
    const imgSrc = props.data.allImageSharp.edges[0].node.gatsbyImageData.images.fallback.src
    props.location.host = "infonutrientes.com.br"
    
    return (
        <ThemeProvider theme={theme}>
            <>
                <SEO
                    title={pageContext.desc}
                    description={pageContext.desc}
                    keywords={pageContext.kw}
                    canonical={`https://${props.location.host}/${pageContext.id}`}
                    base_url={`https://${props.location.host}`}
                    post_date={"2021-03-15"}
                    post_cat={pageContext.id}
                    post_cat_name={pageContext.nome}
                    post_slug={""}
                    post_slug_name={pageContext.nome}
                    post_descp_kps={pageContext.desckps}
                    nutrinfo={false}
                    location={props.location}
                    image={imgSrc}
                />
            </>

            <>
                <Nav />
            </>

            <main>
                <PageMacro info={pageContext} {... props} />
            </main>

            <>
                <Footer />
            </>

        </ThemeProvider>
    )
}

export default MacrosPages;

export const pageQuery = graphql`
    query ($img: String) {
        allImageSharp(filter: {original: {src: {regex: $img}}}) {
            edges {
                node {
                    gatsbyImageData
                }
            }
        }
    }
`;